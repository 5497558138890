import { Box, Container, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import config from "../../../config/config";
import { GuestContext, StoryContext, AuthContext } from "../../../context";
import ReadingProgressBar from "../header/reading_progress_bar";
import ShareModal from "../modals/share_modal";
import RecommendButton from "../widgets/recommend_button";
import { RemoveRedEye } from "@mui/icons-material";

export default function StoryDetailBanner({
  story,
  chapterIndex,
  showProgressBar = false,
}) {
  const { isScrolling } = useContext(GuestContext);
  const { vote, view } = useContext(StoryContext);
  const { user } = useContext(AuthContext);
  const [isVoted, setIsVoted] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [totalVotes, setTotalVotes] = useState(0);
  const [totalViews, setTotalViews] = useState(0);

  const getTotalViews = () => {
    let value = 0;
    story?.storyParts?.forEach((storyPart) => {
      value += storyPart?.views || 0;
    });
    return value;
  };

  useEffect(() => {
    if (story?.storyParts) {
      const votes = story.storyParts.reduce((sum, part) => sum + part.votes, 0);
      setTotalVotes(votes);
      setTotalViews(getTotalViews());
    }
  }, [story]);

  useEffect(() => {
    setIsVoted(
      story?.storyParts[chapterIndex]?.partVotes?.some(
        (vote) => vote.userId === story?.user?.id
      )
    );
    tryView();
  }, [story, chapterIndex]);

  const tryVote = () => {
    const data = {
      userId: story.user?.id,
      storyId: story.id,
      storyPartId: story.storyParts[chapterIndex]?.id,
      isVote: !isVoted,
    };

    vote(data).then(() => {
      setTotalVotes((prev) => prev + (isVoted ? -1 : 1));
      setIsVoted(!isVoted);
    });
  };

  const tryView = () => {
    const data = {
      storyId: story.id,
      storyPartId: story.storyParts[chapterIndex]?.id,
      userId: user?.id,
    };
    view(data).then(() => {
      setTotalViews((prev) => prev + 1);
    });
  };

  return (
    <Box
      sx={{
        position: isScrolling ? "fixed" : "absolute",
        top: isScrolling ? "93px" : "123px",
        width: "100%",
        bgcolor: config.colors.secondBgColor,
        zIndex: 2,
        py: 3,
        boxShadow: isScrolling ? "0 2px 4px rgba(0,0,0,0.1)" : "none",
      }}
    >
      <Container maxWidth="lg">
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Box>
            <Typography
              variant="h4"
              component="h1"
              sx={{
                color: config.colors.primaryTextColor,
                fontFamily: "Libre Franklin",
                fontWeight: 600,
                mb: 1,
              }}
            >
              {story?.title}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: config.colors.primaryTextColor,
                fontFamily: "Libre Franklin",
              }}
            >
              by {story?.user?.fullName || story?.user?.username}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1} mt={1}>
              <RemoveRedEye sx={{ color: config.colors.primaryTextColor }} />
              <Typography
                variant="body2"
                sx={{
                  color: config.colors.primaryTextColor,
                  fontFamily: "Libre Franklin",
                }}
              >
                {totalViews} views
              </Typography>
            </Stack>
          </Box>
          <Stack direction="row" spacing={2} alignItems="center">
            <RecommendButton
              totalRecommendations={totalVotes}
              isRecommended={isVoted}
              recommend={tryVote}
            />
            <Button
              variant="outline-primary"
              onClick={() => setShowShareModal(true)}
            >
              Share
            </Button>
          </Stack>
        </Stack>
      </Container>
      {isScrolling && showProgressBar && <ReadingProgressBar />}
      <ShareModal
        showModal={showShareModal}
        setShowModal={setShowShareModal}
        storyUrl={window.location.href}
      />
    </Box>
  );
}
