import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {
  Footer,
  StoryDetailSetting,
  VideoUploadingModal,
  WritingHeader,
} from "../../components";
import {
  AxiosContext,
  ShareContext,
  StoryContext,
  AuthContext,
  GuestContext,
  ServiceContext,
} from "../../../context";
import Config from "../../../config/config";
import VideocamIcon from "@mui/icons-material/Videocam";
import ImageIcon from "@mui/icons-material/Image";

import Editor, { composeDecorators } from "@draft-js-plugins/editor";
import {
  EditorState,
  AtomicBlockUtils,
  convertToRaw,
  convertFromRaw,
  ContentState,
  Modifier,
  RichUtils,
} from "draft-js";
import createImagePlugin from "@draft-js-plugins/image";
import createVideoPlugin from "@draft-js-plugins/video";
import createInlineToolbarPlugin, {
  Separator,
} from "@draft-js-plugins/inline-toolbar";
import createAlignmentPlugin from "@draft-js-plugins/alignment";
import createFocusPlugin from "@draft-js-plugins/focus";
import createResizeablePlugin from "@draft-js-plugins/resizeable";
import createTextAlignmentPlugin from "@draft-js-plugins/text-alignment";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton,
  createInlineStyleButton,
} from "@draft-js-plugins/buttons";
import "draft-js/dist/Draft.css";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "@draft-js-plugins/image/lib/plugin.css";
import "@draft-js-plugins/inline-toolbar/lib/plugin.css";
import "@draft-js-plugins/alignment/lib/plugin.css";
import "@draft-js-plugins/text-alignment/lib/plugin.css";
import "@draft-js-plugins/focus/lib/plugin.css";
import "../../styles/editor_rich_style.scss";
import { toast } from "react-toastify";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import imgAvatar from "../../../assets/images/avatar.svg";
import imgSettings from "../../../assets/images/icons/settings.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { isValidEmail } from "../../../utils/helpers";
import { Box, Container, Stack, IconButton } from "@mui/material";
import TextDecreaseIcon from "@mui/icons-material/TextDecrease";
import TextIncreaseIcon from "@mui/icons-material/TextIncrease";
import config from "../../../config/config";
import { Helpers } from "../../../utils";
import htmlToDraft from "html-to-draftjs";


const focusPlugin = createFocusPlugin();
const resizeablePlugin = createResizeablePlugin();
const alignmentPlugin = createAlignmentPlugin();

const { AlignmentTool } = alignmentPlugin;

const decorator = composeDecorators(
  resizeablePlugin.decorator,
  alignmentPlugin.decorator,
  focusPlugin.decorator,
);

const imagePlugin = createImagePlugin({ decorator });
const videoPlugin = createVideoPlugin();
const textAlignmentPlugin = createTextAlignmentPlugin();
const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;

const { types } = videoPlugin;

const plugins = [
  focusPlugin,
  alignmentPlugin,
  resizeablePlugin,
  videoPlugin,
  imagePlugin,
  textAlignmentPlugin,
  inlineToolbarPlugin,
];

export default function WritePartPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { storyId, storyPartId } = useParams();
  const { setLoading } = useContext(AxiosContext);
  const {
    getStoryPart,
    updateStoryPart,
    currentStory,
    currentStoryPart,
    updateStoryPartsOrder,
    newStoryPart,
    updateStory,
  } = useContext(StoryContext);
  const { shareStory } = useContext(ShareContext);
  const { token, user } = useContext(AuthContext);
  const { uploadImage } = useContext(ServiceContext);
  const { setPrevRoute, getTempStoryPart, removeTempStoryPart } =
    useContext(GuestContext);
  const [formData, setFormData] = useState({
    id: storyPartId,
    title: "",
    content: ``,
    coverImage: "",
    coverVideo: "",
    order: "",
    status: "DRAFT",
    estimatedReadingTime: 0,
  });
  const [modalStory, setModalStory] = useState(null);
  const [shareModal, setShareModal] = useState(null);
  const [shareFormData, setShareFormData] = useState({
    shareEmail: "",
    userId: "",
    storyId: "",
    shareRole: "Viewer",
  });
  const editorRef = useRef(null);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const imageUpload = useRef(null);
  const videoUpload = useRef(null);

  const [openVideoModal, setOpenVideoModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (token) {
      setLoading(true);
      getStoryPart(storyPartId)
        .then((response) => {
          if (!response.data.storyPart) {
            navigate("/mystories");
          } else {
            const storyPart = response.data.storyPart;
            setFormData({
              ...formData,
              id: storyPart.id,
              title: storyPart.title,
              content: storyPart.content,
              coverImage: storyPart.coverImage,
              coverVideo: storyPart.coverVideo,
              order: storyPart.order,
              status: storyPart.status,
              estimatedReadingTime: storyPart.estimatedReadingTime,
            });
            //
            // let prevRoute = getPrevRoute();
            let tempStoryPart = getTempStoryPart();
            let _storyPartContent = ``;
            if (!storyPart.content && tempStoryPart) {
              setFormData({
                ...formData,
                title: tempStoryPart.title,
                content: tempStoryPart.content,
              });
            } else {
              _storyPartContent = storyPart.content ? storyPart.content : "";
            }
            let _editorState;
            if (Helpers.isJsonString(_storyPartContent)) {
              _editorState = EditorState.createWithContent(
                convertFromRaw(JSON.parse(_storyPartContent))
              );
            } else {
              const blocksFromHtml = htmlToDraft(_storyPartContent);
              const { contentBlocks, entityMap } = blocksFromHtml;
              const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
              );
              _editorState = EditorState.createWithContent(contentState);
            }

            setEditorState(_editorState);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [token, storyPartId]);

  const handleUpdateTitle = (title) => {
    setLoading(true);
    updateStoryPart({ ...formData, title: title, onlyField: "title" }).then(
      (response) => {
        setFormData({
          ...formData,
          title: title,
        });
        toast("Successfully title updated!");
        setLoading(false);
      }
    );
  };

  const handleClickPreview = () => {
    setPrevRoute(location);
    navigate(`/story/${storyId}/${storyPartId}?preview=true`);
  };

  const handleClickPublish = () => {
    setLoading(true);
    updateStoryPart({ ...formData, status: "PUBLISH" }).then((response) => {
      if (response.status) {
        removeTempStoryPart();
        // navigate(`/story/${storyId}/${storyPartId}`);
        navigate(`/mystories/${storyId}`);
      }
      setLoading(false);
    });
  };

  const handleClickSave = () => {
    setLoading(true);
    updateStoryPart({ ...formData, status: "DRAFT" }).then((response) => {
      if (response.status) {
        toast("Successfully saved");
        removeTempStoryPart();
      } else {
        toast(response.data);
      }
      setLoading(false);
    });
  };

  const handleClickNewChapter = () => {
    setLoading(true);
    updateStoryPart(formData).then((response) => {
      if (response.status) {
        removeTempStoryPart();
        createNewStoryPart();
      } else {
        toast(response.data);
      }
      setLoading(false);
    });
  };

  const createNewStoryPart = () => {
    if (token && storyId) {
      setLoading(true);
      newStoryPart({ storyId: storyId, userId: user.id })
        .then((response) => {
          setLoading(false);
          const storyPart = response.data.storyPart;
          if (!storyPart) {
            navigate(`/mystories`);
          } else {
            navigate(`/mystories/${storyId}/write/${storyPart.id}`);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  };

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...modalStory.storyParts];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setModalStory({ ...modalStory, storyParts: updatedList });
    updateStoryPartsOrder({ storyParts: updatedList }).then((res) => {
      if (res.status) {
        getStoryPart(storyPartId);
      }
    });
  };

  const handleClickStoryDetails = () => {
    setPrevRoute(location);
    navigate(`/mystories/${storyId}`);
  };

  const handleChangeAccessType = (option) => {
    setLoading(true);
    updateStory({ id: shareModal.id, accessType: option }).then((res) => {
      setLoading(false);
      if (res.status) {
        getStoryPart(storyPartId);
        if (res.data.story) setShareModal(res.data.story);
        toast("Successfully updated.");
      } else {
        toast(res.data);
      }
    });
  };

  const handleClickCopyLink = () => {
    let linkText = `${window.location.origin}/story/${shareModal.id}?utm_source=web&utm_medium=email&utm_content=sharing`;
    navigator.clipboard.writeText(linkText);
    toast("Link copied");
  };

  const handleClickShareDone = () => {
    setShareModal(null);
  };

  const handleClickShareSend = () => {
    console.log("=== shareFD ===", shareFormData);
    setLoading(true);
    shareStory(shareFormData).then((res) => {
      setLoading(false);
      console.log("=== shareFD res ===", res);
      if (res.status) {
        getStoryPart(storyPartId);
        if (res.data.story) {
          setShareModal(res.data.story);
        }
        toast("Successfully shared.");
      } else {
        toast(res.data);
      }
      setShareFormData({ ...shareFormData, shareEmail: "" });
    });
  };

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
    console.log(editorState.getCurrentContent());
    let editorContent = editorState.getCurrentContent();
    let totalWords = editorContent.getPlainText().split(" ").length;
    let _estimatedReadingTime = Math.ceil(totalWords / Config.WPM);
    const description = JSON.stringify(convertToRaw(editorContent));
    setFormData({
      ...formData,
      content: description,
      estimatedReadingTime: _estimatedReadingTime,
    });
  };

  const getBlockStyle = (block) => {
    switch (block.getType()) {
      case "blockquote":
        return "RichEditor-blockquote";
      case "left":
        return "align-left";
      case "center":
        return "align-center";
      case "right":
        return "align-right";
      case "justify":
        return "align-justify";
      default:
        return null;
    }
  };

  const insertImage = (imgSrc) => {
    console.log("insert image: " + imgSrc);
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      config.entityType.image,
      "IMMUTABLE",
      { src: imgSrc, caption: "" }
    );
    console.log(contentStateWithEntity);
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    setLoading(false);
    handleEditorChange(
      AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
    );
  };

  const insertVideo = (videoSrc) => {
    console.log("insert video: " + videoSrc, types.VIDEOTYPE);
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      types.VIDEOTYPE,
      "IMMUTABLE",
      { src: videoSrc }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    setLoading(false);
    handleEditorChange(
      AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
    );
  };

  const fileReader = (file, index) => {
    let reader = new FileReader();

    reader.onloadend = () => {
      setLoading(true);
      uploadImage({ base64: reader.result }).then((response) => {
        console.log(response.data);

        if (response.status) {
          console.log("successfully render image uploading...");
          if (index === "image") {
            insertImage(response.data);
          }
          if (index === "video") {
            insertVideo(response.data);
          }
        }
      });
    };
    reader.onerror = (error) => {
      console.log(error);
      setLoading(false);
    };
    reader.readAsDataURL(file);
  };

  const uploadImageChange = (e) => {
    const items = e.target.files;
    console.log(items);
    if (items.length > 0) {
      const item = items[0];
      if (item?.size > 3 * 1024 * 1024) {
        toast.warn("Please upload less than 3M");
      } else {
        console.log("uploading...");
        fileReader(item, "image");
      }
    }
  };

  const uploadVideoChange = (e) => {
    console.log(e);
    const items = e.target.files;
    console.log(items);
    if (items.length > 0) {
      const item = items[0];
      if (item?.size > 50 * 1024 * 1024) {
        toast.warn("Please upload less than 50M");
      } else {
        fileReader(item, "video");
      }
    }
  };

  const handleImageUpload = () => {
    if (imageUpload) {
      imageUpload.current.click();
    }
  };

  const getInlineStylesForCollapsedSelection = (editorState, style) => {
    console.log(editorState.getCurrentInlineStyle());
    return editorState.getCurrentInlineStyle().has(style);
  };

  const fontChangeAction = (index) => {
    console.log("font change");
    fontChange(index);
  };

  const fontChange = (addValue) => {
    const FONT_SIZE_LIST = config.FONT_SIZE_LIST;
    let fontSizeXpIndex = 6;
    // Get current selection
    const selection = editorState.getSelection();
    // Get current content
    const contentState = editorState.getCurrentContent();
    FONT_SIZE_LIST.map((item, index) => {
      if (getInlineStylesForCollapsedSelection(editorState, item)) {
        fontSizeXpIndex = index;
      }
    });

    const customStyleIndex = fontSizeXpIndex + addValue;
    console.log(FONT_SIZE_LIST[fontSizeXpIndex]);
    if (customStyleIndex == FONT_SIZE_LIST.length || customStyleIndex < 0) {
      return;
    }

    const customStyle = FONT_SIZE_LIST[customStyleIndex];
    console.log(customStyle);

    // remove style
    // Get the current block
    const removeContentState = Modifier.removeInlineStyle(
      contentState,
      selection,
      FONT_SIZE_LIST[fontSizeXpIndex]
    );

    // Get the current block
    const nextContentState = Modifier.applyInlineStyle(
      removeContentState,
      selection,
      customStyle
    );

    const newEditorState = EditorState.push(
      editorState,
      nextContentState,
      "change-inline-style"
    );

    handleEditorChange(newEditorState);
  };

  return (
    <Box sx={{ bgcolor: config.colors.bgWhiteColor }}>
      <WritingHeader
        storyId={storyId}
        storyPartId={storyPartId}
        currentStory={currentStory}
        currentStoryPart={currentStoryPart}
        formData={formData}
        handleClickPublish={handleClickPublish}
        handleClickPreview={handleClickPreview}
        handleClickNewChapter={handleClickNewChapter}
        handleClickSaveDraft={handleClickSave}
        handleUpdateTitle={handleUpdateTitle}
        showReadingProgress={false}
      />
      <Box
        sx={{
          pt: "140px",
          pb: "40px",
          bgcolor: config.colors.primaryBgColor,
        }}
      >
        <Container maxWidth={"lg"}>
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            spacing={2}
            alignItems={"center"}
            justifyContent={"space-between"}
          ></Stack>
          <Stack
            direction={"column"}
            spacing={2}
            sx={{
              borderRadius: 4,
              bgcolor: config.colors.bgWhiteColor,
              boxShadow: `1px 5px 3px 3px rgba(71,52,130,.1)`,
            }}
            onClick={() => {
              console.log("sldfkjafljldj");
              if (editorRef.current) {
                editorRef.current.focus();
              } else {
                console.log("not current");
              }
            }}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              sx={{
                borderRadius: 4,
                bgcolor: config.colors.bgWhiteColor,
                pt: 3,
                paddingX: 3,
                pb: 0,
                position: "sticky",
                top: "93px",
                zIndex: 100,
              }}
            >
              <input
                type="file"
                ref={imageUpload}
                onChange={uploadImageChange}
                accept="image/png, image/jpeg, image/jpg, image/gif"
                style={{ display: "none" }}
              />
              <input
                type="file"
                ref={videoUpload}
                onChange={uploadVideoChange}
                accept="video/mp4"
                style={{ display: "none" }}
              />
              <VideocamIcon
                sx={{
                  cursor: "pointer",
                  color: config.colors.greyTextColor,
                  fontSize: 32,
                }}
                onClick={() => {
                  console.log("video uploading...");
                  // handleVideoUpload();
                  setOpenVideoModal(true);
                }}
              />
              <ImageIcon
                sx={{
                  cursor: "pointer",
                  color: config.colors.greyTextColor,
                  fontSize: 28,
                }}
                onClick={() => {
                  console.log("image uploading...");
                  handleImageUpload();
                }}
              />
              <StoryDetailSetting
                handleDetail={handleClickStoryDetails}
                handleSharing={() => {
                  setShareModal(currentStory);
                  setShareFormData({
                    ...shareFormData,
                    userId: user?.id,
                    storyId: storyId,
                  });
                }}
                handleGuide={() => {
                  console.log("writing guide");
                }}
              />
            </Stack>
            <Box
              sx={{
                fontFamily: "Libre Franklin",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 400,
                paddingX: 2,
                pt: 0,
              }}
            >
              <Editor
                editorKey={"editor"}
                customStyleMap={config.customStyleMap}
                blockStyleFn={getBlockStyle}
                editorState={editorState}
                onChange={handleEditorChange}
                plugins={plugins}
                placeholder="Tell your story..."
                ref={editorRef}
              />
              <AlignmentTool />
              <InlineToolbar>
                {
                  // may be use React.Fragment instead of div to improve perfomance after React 16
                  (externalProps) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <BoldButton {...externalProps} />
                      <ItalicButton {...externalProps} />
                      <UnderlineButton {...externalProps} />
                      <button
                        className="b181v2oy"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => fontChangeAction(1)}
                        {...externalProps}
                      >
                        <TextIncreaseIcon />
                      </button>
                      <button
                        className="b181v2oy"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => fontChangeAction(-1)}
                        {...externalProps}
                      >
                        <TextDecreaseIcon />
                      </button>
                      {/* <CodeButton {...externalProps} />
                <Separator {...externalProps} /> */}
                      {/* <textAlignmentPlugin.TextAlignment {...externalProps}/> */}
                      <UnorderedListButton {...externalProps} />
                      <OrderedListButton {...externalProps} />
                      {/* <BlockquoteButton {...externalProps} />
                <CodeBlockButton {...externalProps} /> */}
                    </div>
                  )
                }
              </InlineToolbar>
            </Box>
          </Stack>
        </Container>
      </Box>
      <Footer />
      {/* Share Story Modal */}
      <Modal
        show={shareModal}
        onHide={() => setShareModal(null)}
        backdrop="static"
        keyboard={false}
        className="story-action-modal"
      >
        <Modal.Header className="border-0">
          <Modal.Title>Share Story</Modal.Title>
          <Button className="btn-settings">
            <img src={imgSettings} alt="" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="modal-subline">
              "Remembering Grandma, word limit..."
            </div>
            <div className="form-group">
              <label>Add people and groups</label>
              <div className="share-form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mom@yahoo.com"
                  value={shareFormData.shareEmail}
                  onChange={(e) =>
                    setShareFormData({
                      ...shareFormData,
                      shareEmail: e.target.value,
                    })
                  }
                />
                {shareFormData.shareEmail &&
                  isValidEmail(shareFormData.shareEmail) && (
                    <select
                      className="form-control share-role"
                      value={shareFormData.shareRole}
                      onChange={(e) =>
                        setShareFormData({
                          ...shareFormData,
                          shareRole: e.target.value,
                        })
                      }
                    >
                      <option value="Viewer">Viewer</option>
                      <option value="Commenter">Commenter</option>
                      <option value="Editor">Editor</option>
                    </select>
                  )}
              </div>
            </div>
            <h5 className="modal-subtitle">People with access</h5>
            <div className="row">
              <div className="col-12 col-sm-6 col-md-6">
                <div className="user-item">
                  <div className="user-avatar">
                    <img src={imgAvatar} alt="" />
                  </div>
                  <div className="user-info">
                    <div className="user-name">
                      {shareModal?.user?.fullName
                        ? shareModal?.user?.fullName
                        : shareModal?.user?.username}{" "}
                      (You - Owner)
                    </div>
                    <div className="user-email">{shareModal?.user?.email}</div>
                  </div>
                </div>
              </div>
              {shareModal &&
                shareModal?.shares?.map((item, index) => (
                  <div className="col-12 col-sm-6 col-md-6" key={index}>
                    <div className="user-item">
                      <div className="user-avatar">
                        <img src={imgAvatar} alt="" />
                      </div>
                      <div className="user-info">
                        <div className="user-name">
                          {item?.shareUser?.fullName
                            ? item?.shareUser?.fullName
                            : item?.shareUser?.username}{" "}
                          ({item?.shareRole})
                        </div>
                        <div className="user-email">
                          {item?.shareUser?.email
                            ? item?.shareUser?.email
                            : item?.shareEmail}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <h5 className="modal-subtitle">General access</h5>
            <div className="access-option">
              <Dropdown className="access-option-dropdown">
                <Dropdown.Toggle variant="default">
                  {shareModal?.accessType == "RESTRICTED"
                    ? "Restricted"
                    : "Anyone with the link"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => handleChangeAccessType("RESTRICTED")}
                  >
                    Restricted
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => handleChangeAccessType("ANYONE")}
                  >
                    Anyone with the link
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <p>
                {shareModal?.accessType == "RESTRICTED"
                  ? "Only people with access can open with the link"
                  : "Anyone on the internet with the link can view"}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            className="me-auto btn-copy-link"
            onClick={() => handleClickCopyLink()}
          >
            Copy Link
          </Button>
          {shareFormData.shareEmail &&
          isValidEmail(shareFormData.shareEmail) ? (
            <Button
              variant="primary"
              className="ms-auto btn-close-modal"
              onClick={() => handleClickShareSend()}
            >
              Send
            </Button>
          ) : (
            <Button
              variant="primary"
              className="ms-auto btn-close-modal"
              onClick={() => handleClickShareDone()}
            >
              Done
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {/* Story Organizer Modal */}
      <Modal
        show={modalStory !== null}
        onHide={() => setModalStory(null)}
        backdrop="static"
        keyboard={false}
        className="story-action-modal lg-modal"
      >
        <Modal.Header className="border-0">
          <Modal.Title>Story Organizer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="modal-subline">"{modalStory?.title}"</div>
            <p>Drag and drop chapters to reorder your story.</p>
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="list-container">
                {(provided) => (
                  <div
                    className="part-list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {modalStory?.storyParts.map((item, index) => (
                      <Draggable
                        key={item.id.toString()}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="part-item"
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <div className="part-preview"></div>
                            <div className="part-info">
                              <div className="part-headline">
                                {`Chapter ${index + 1}`}
                              </div>
                              <div className="part-subline">{item.title}</div>
                            </div>
                            {/* <Dropdown className="action-dropdown ms-auto">
                          <Dropdown.Toggle variant="default">
                            <img src={imgDots} className="" alt="..." />
                          </Dropdown.Toggle>
                  
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => navigate(`/story/${item.storyId}/${item.id}`)}>View</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => navigate(`/mystories/${item.storyId}/write/${item.id}`)}>Edit</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => handleClickStoryPartPublishToggle(item)}>{item.status === 'PUBLISH' ? 'Unpublish' : 'Publish'}</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => handleClickStoryPartDelete(item.id)}>Delete</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="primary"
            className="btn-close-modal mx-auto"
            onClick={() => setModalStory(null)}
          >
            Exit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* {video uploading modal} */}
      <VideoUploadingModal
        isOpenModal={openVideoModal}
        closeModal={() => setOpenVideoModal(false)}
        onSubmit={(file) => fileReader(file, "video")}
      />
    </Box>
  );
}
