import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import apis from "../config/apis";

const AuthContext = createContext(undefined);

const AuthContextProvider = (props) => {
  const [token, setToken] = useState("");
  const [user, setUser] = useState(null);
  const [cookies, setCookies, removeCookie] = useCookies(["token"]);

  const axiosInstance = axios.create({
    baseURL: apis.baseUrl,
    headers: {
      "x-access-token": token,
    },
  });

  useEffect(() => {
    setToken(cookies.token);
    if (token) {
      getMyInfo().then((res) => {
        const data = res;
        if (data?.status) {
          setUser(data.data);
        }
      });
    }
  }, [token]);

  const getToken = () => {
    return cookies.token;
  };

  const login = (data) => {
    return axiosInstance({
      method: "POST",
      url: apis.login,
      data,
    })
      .then((response) => {
        console.log("== login ==", response);
        const data = response.data;
        if (data.status) {
          setUser(data.data);
          saveToken(data.data.token);
        }
        return data.data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const register = (data) => {
    return axiosInstance({
      method: "POST",
      url: apis.register,
      data,
    })
      .then((response) => {
        console.log("== register ==", response);
        const data = response.data;
        if (data.status) {
          setUser(data.data);
          saveToken(data.data.token);
        }
        return data.data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const socialAuthentication = (data) => {
    return axiosInstance({
      method: "POST",
      url: apis.socialAuth,
      data,
    })
      .then((response) => {
        const data = response.data;
        if (data.status) {
          setUser(data.data);
          saveToken(data.data.token);
        }
        return data.data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const getMyInfo = () => {
    return axiosInstance({
      method: "GET",
      url: apis.myInfo,
    })
      .then((response) => {
        if (response.data?.status) {
          if (response.data.data) {
            setUser(response.data.data);
          } else {
            logout();
          }
        }
        return response.data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const updatePassword = (data) => {
    return axiosInstance({
      method: "PUT",
      url: apis.users + "/password/" + user.id,
      data,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const saveToken = (token, isAdmin = false) => {
    setToken(token);
    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 10);
    setCookies("token", token, { expires: tomorrow });
  };

  const logout = () => {
    setToken("");
    setUser(null);
    removeCookie("token");
    deleteAllCookies();
    return !token ? true : false;
  };

  const forgotPassword = (data) => {
    return axiosInstance({
      method: "POST",
      url: apis.forgotPassword,
      data,
    })
      .then((response) => {
        const data = response.data;
        return data.data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const deleteAllCookies = () => {
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  };

  const getGoogleUserInfo = (access_token) => {
    return axiosInstance({
      method: "GET",
      url: `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${access_token}`,
    })
      .then((response) => {
        console.log("== google user ==", response);
        if (response.data.status) {
        }
        return response.data;
      })
      .catch((error) => {
        console.log("err: ", error);
      });
  };

  const sendWaitingList = (data) => {
    return axiosInstance({
      method: "POST",
      url: apis.waitingList,
      data,
    })
      .then((response) => {
        const data = response.data;
        return data.data;
      })
      .catch((error) => {
        console.log("err: ", error);
        return null;
      });
  };

  const providerValue = {
    token,
    user,
    setUser,
    saveToken,
    getToken,
    login,
    register,
    socialAuthentication,
    logout,
    getMyInfo,
    updatePassword,
    forgotPassword,
    getGoogleUserInfo,
    sendWaitingList,
  };

  return (
    <AuthContext.Provider value={providerValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
