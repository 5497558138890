import React, { createContext, useContext, useState } from 'react';
import { AxiosContext } from './axios_context';
import apis from '../config/apis';
import Config from '../config/config';
import { useSearchParams } from 'react-router-dom';

const StoryContext = createContext();

const StoryContextProvider = (props) => {

    const [searchParams] = useSearchParams();
    const utm_source = searchParams.get("utm_source");
    const utm_medium = searchParams.get("utm_medium");
    const utm_content = searchParams.get("utm_content");

    const { axiosInstance } = useContext(AxiosContext);
    const [currentStory, setCurrentStory] = useState(Config.defaultStoryInfo);
    const [currentStoryPart, setCurrentStoryPart] = useState(Config.defaultWriteInfo);
    const [myStories, setMyStories] = useState([]);
    const [myStoriesPagination, setMyStoriesPagination] = useState(null);
    const [paidStories, setPaidStories] = useState([]);
    const [stories, setStories] = useState([]);
    const [storiesPagination, setStoriesPagination] = useState(null);
    const [recommendStories, setRecommendStories] = useState([]);
    const [topRecommendedStories, setTopRecommendedStories] = useState([]);

    const createStory = (data) => {
        return axiosInstance({
            method: 'POST',
            url: apis.stories,
            data
        }).then(response => {
            setCurrentStory(response.data.data.story);
            setCurrentStoryPart(response.data.data.storyPart);
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getStory = (storyId) => {
        return axiosInstance({
            method: 'GET',
            url: `${apis.stories}/${storyId}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_content=${utm_content}`,
        }).then(response => {
            console.log('- current story -', response.data);
            if (response.data.data.story) {
                setCurrentStory(response.data.data.story);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const updateStory = (data) => {
        return axiosInstance({
            method: 'PUT',
            url: apis.stories + '/' + data.id,
            data
        }).then(response => {
            setCurrentStory(response.data.data.story);
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getStoryPart = (storyPartId) => {
        return axiosInstance({
            method: 'GET',
            url: apis.storyParts + '/' + storyPartId,
        }).then(response => {
            if (response.data.data.storyPart) {
                setCurrentStoryPart(response.data.data.storyPart);
                setCurrentStory(response.data.data.storyPart.story);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const updateStoryPart = (data) => {
        return axiosInstance({
            method: 'PUT',
            url: apis.storyParts + '/' + data.id,
            data
        }).then(response => {
            setCurrentStoryPart(response.data.data.storyPart);
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getMyStories = (params = '') => {
        return axiosInstance({
            method: 'GET',
            url: apis.mystories + '?' + params,
        }).then(response => {
            if (response.data.status) {
                const pagination = response.data.data.pagination;
                let items = [];
                if (pagination?.currentPage > 1) {
                    items = myStories;
                }
                items.push(...response.data.data.stories);
                setMyStories(items);
                setMyStoriesPagination(pagination);
                console.log('== my stories ==', items);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const deleteStory = (id) => {
        return axiosInstance({
            method: 'DELETE',
            url: apis.stories + '/' + id,
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const deleteStoryPart = (id) => {
        return axiosInstance({
            method: 'DELETE',
            url: apis.storyParts + '/' + id,
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const newStoryPart = (data) => {
        return axiosInstance({
            method: 'POST',
            url: apis.storyParts + '/new',
            data
        }).then(response => {
            if (response.data.data.storyPart) {
                setCurrentStoryPart(response.data.data.storyPart);
            }
            if (response.data.data.story) {
                setCurrentStory(response.data.data.story);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    // const createStoryPart = (data) => {
    //     return axiosInstance({
    //         method: 'POST',
    //         url: apis.storyParts,
    //         data
    //     }).then(response => {
    //         return response.data;
    //     }).catch(error => {
    //         console.log('err: ', error);
    //     });
    // }

    const getPaidStories = (params) => {
        return axiosInstance({
            method: 'GET',
            url: apis.stories + '/paid?' + params,
        }).then(response => {
            if (response.data.status) {
                setPaidStories(response.data.data.stories);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getStories = (params) => {
        return axiosInstance({
            method: 'GET',
            url: apis.stories + '?' + params,
        }).then(response => {
            if (response.data.status) {
                const pagination = response.data.data.pagination;
                let items = [];
                if (pagination?.currentPage > 1) {
                    items = stories;
                }
                items.push(...response.data.data.stories);
                setStories(items);
                setStoriesPagination(pagination);
                console.log('=== stories ===', items);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getRecommendStories = (storyId) => {
        return axiosInstance({
            method: 'GET',
            url: apis.stories + '/recommend/' + storyId,
        }).then(response => {
            if (response.data.status) {
                setRecommendStories(response.data.data.stories);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getTopRecommendedStories = () => {
        return axiosInstance({
            method: 'GET',
            url: apis.stories + '/topRecommended',
        }).then(response => {
            if (response.data.status) {
                setTopRecommendedStories(response.data.data.stories);
            }
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    };

    const vote = (data) => {
        return axiosInstance({
            method: 'POST',
            url: apis.storyParts + '/vote',
            data
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const view = (data) => {
        return axiosInstance({
            method: 'POST',
            url: apis.storyParts + '/view',
            data
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const updateStoryPartsOrder = (data) => {
        return axiosInstance({
            method: 'POST',
            url: apis.storyParts + '/reorders',
            data
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const checkStoryNoteByStoryId = (storyId) => {
        return axiosInstance({
            method: 'GET',
            url: apis.mystories + '/checkNotes/' + storyId,
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const getStoryNoteById = (storyNoteId) => {
        return axiosInstance({
            method: 'GET',
            url: apis.mystories + '/notes/' + storyNoteId,
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const updateStoryNote = (data) => {
        return axiosInstance({
            method: 'PUT',
            url: apis.mystories + '/notes/' + data.id,
            data
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    // Story Importing
    const importStoryFromParagraphs = (data) => {
        return axiosInstance({
            method: 'POST',
            url: `${apis.mystories}/import`,
            data
        }).then(response => {
            return response.data;
        }).catch(error => {
            console.log('err: ', error);
        });
    }

    const providerValue = {
        createStory,
        getStory,
        updateStory,
        newStoryPart,
        // createStoryPart,
        getStoryPart,
        updateStoryPart,
        getMyStories,
        deleteStory,
        deleteStoryPart,
        myStories,
        setMyStories,
        myStoriesPagination,
        setMyStoriesPagination,
        currentStory,
        currentStoryPart,
        setCurrentStory,
        setCurrentStoryPart,
        getPaidStories,
        getStories,
        paidStories,
        stories,
        setStories,
        storiesPagination,
        setStoriesPagination,
        getRecommendStories,
        recommendStories,
        topRecommendedStories,
        vote,
        view,
        updateStoryPartsOrder,
        checkStoryNoteByStoryId,
        getStoryNoteById,
        updateStoryNote,
        // importing story
        importStoryFromParagraphs,
        getTopRecommendedStories,
    }

    return (
        <StoryContext.Provider value={providerValue}>
            {props.children}
        </StoryContext.Provider>
    );

}

export {
    StoryContext, StoryContextProvider
}