import React from 'react';
import {
    HomePage,
    WaitingListPage,
    //
    LoginPage,
    RegisterPage,
    RegisterStep1Page,
    RegisterStep2Page,
    // Start
    StartPage,
    StartWritePage,
    WriterInvitePage,
    WriterInviteSuccessPage,
    WriterSurveyPage,
    ReaderSurveyPage,
    StartSuccessPage,
    // My stories
    NewStoryPage,
    EditStoryPage,
    MyStoriesPage,
    StoryNotePage,
    WritePartPage,
    StoriesPage,
    TypeStoriesPage,
    CategoryStoriesPage,
    SearchPage,
    UserPage,
    StoryPage,
    AuthorsPage,
    // StoryPartPage,
    LibraryPage,
    ProfilePage,
    ProfileEditPage,
    ProfilePasswordPage,
    ForgotPasswordPage,
    TagsStoriesPage,
    SettingsPage,
    // Delegate
    DelegatePage,
    DelegateStoriesPage,
    //
    PrivacyPage,
    InvitePage,
    AboutUsPage,
    LegalAgreementPage,

} from '../intefaces/pages';
import {
    AdminLoginPage,

    EmailTemplateList,
    WaitingUsersPage,
    WaitingUsersCreatePage,
} from "../intefaces/admin_pages";
import { Navigate } from 'react-router-dom';


const routes = [
  // TODO: Use react app api url env var to control which page is showed?
    { path: '', exact: true, auth: false, name: 'HomePage', component: <WaitingListPage/>},
    // { path: '', exact: true, auth: false, name: 'HomePage', component: <HomePage/> },
    { path: '/waiting-list', exact: true, auth: false, name: 'WaitingPage', component: <WaitingListPage/> },
    // auth
    { path: '/auth/login', exact: true, auth: false, name: 'LoginPage', component: <LoginPage/> },
    { path: '/auth/register', exact: true, auth: false, name: 'RegisterPage', component: <RegisterPage/> },
    { path: '/auth/register/step1', exact: true, auth: false, name: 'RegisterStep1Page', component: <RegisterStep1Page/> },
    { path: '/auth/register/step2', exact: true, auth: false, name: 'RegisterStep2Page', component: <RegisterStep2Page/> },
    { path: '/auth/forgot-password', exact: true, auth: false, name: 'ForgotPasswordPage', component: <ForgotPasswordPage/> },
    // start
    { path: '/start', exact: true, auth: true, name: 'StartPage', component: <StartPage/> },
    { path: '/start/write', exact: true, auth: true, name: 'StartWritePage', component: <StartWritePage/> },
    { path: '/start/invite', exact: true, auth: true, name: 'WriterInvitePage', component: <WriterInvitePage/> },
    { path: '/start/invite/success', exact: true, auth: true, name: 'WriterInviteSuccessPage', component: <WriterInviteSuccessPage/> },
    { path: '/start/writersurvey', exact: true, auth: true, name: 'WriterSurveyPage', component: <WriterSurveyPage/> },
    { path: '/start/readersurvey', exact: true, auth: true, name: 'ReaderSurveyPage', component: <ReaderSurveyPage/> },
    { path: '/start/success', exact: true, auth: true, name: 'StartSuccessPage', component: <StartSuccessPage/> },
    // stories
    { path: '/stories', exact: true, auth: false, name: 'StoriesPage', component: <StoriesPage/> },
    { path: '/stories/types/:typeSlug', exact: true, auth: false, name: 'TypeStoriesPage', component: <TypeStoriesPage/> },
    { path: '/stories/categories/:categorySlug', exact: true, auth: false, name: 'CategoryStoriesPage', component: <CategoryStoriesPage/> },
    { path: '/stories/tags', exact: true, auth: false, name: 'TagsStoriesRedirectPage', component: <Navigate to="/stories" /> },
    { path: '/stories/tags/:tagsSlug', exact: true, auth: false, name: 'TagsStoriesPage', component: <TagsStoriesPage/> },
    { path: '/search', exact: true, auth: false, name: 'SearchPage', component: <SearchPage/> },
    { path: '/search/:queryText', exact: true, auth: false, name: 'SearchPage', component: <SearchPage/> },
    { path: '/users/:userId', exact: true, auth: false, name: 'UserPage', component: <UserPage/> },
    { path: '/story/:storyId', exact: true, auth: false, name: 'StoryPage', component: <StoryPage/> },
    { path: '/story/:storyId/:storyPartId', exact: true, auth: false, name: 'StoryPage', component: <StoryPage/> },
    { path: '/authors/top', exact: true, auth: false, name: 'AuthorsPage', component: <AuthorsPage/> },
    { path: '/authors/:userId', exact: true, auth: false, name: 'ProfilePage', component: <ProfilePage/> },
    // policy
    { path: '/privacy', exact: true, auth: false, name: 'PrivacyPage', component: <PrivacyPage/> },
    //about us
    { path: '/about', exact: true, auth: false, name: 'AboutUsPage', component: <AboutUsPage/> },
    //legal agreement
    { path: '/legal', exact: true, auth: false, name: 'LegalAgreementPage', component: <LegalAgreementPage/> },

    // invite
    { path: '/invite/:inviteToken', exact: true, auth: false, name: 'InvitePage', component: <InvitePage/> },

    // private
    // write
    { path: '/mystories/new', exact: true, auth: false, name: 'NewStoryPage', component: <NewStoryPage/> },
    { path: '/mystories/:storyId/write/:storyPartId', exact: true, auth: true, name: 'WritePartPage', component: <WritePartPage/> },
    { path: '/mystories/:storyId/notes/:storyNoteId', exact: true, auth: true, name: 'StoryNotePage', component: <StoryNotePage/> },
    { path: '/mystories', exact: true, auth: true, name: 'MyStoriesPage', component: <MyStoriesPage/> },
    { path: '/mystories/:storyId', exact: true, auth: true, name: 'EditStoryPage', component: <EditStoryPage/> },
    // profile
    { path: '/user/:userId', exact: true, auth: false, name: 'ProfilePage', component: <ProfilePage/> },
    { path: '/profile/edit', exact: true, auth: true, name: 'ProfileEditPage', component: <ProfileEditPage/> },
    { path: '/profile/change-password', exact: true, auth: true, name: 'ProfilePasswordPage', component: <ProfilePasswordPage/> },
    // library
    { path: '/library', exact: true, auth: true, name: 'LibraryPage', component: <LibraryPage/> },
    // delegate
    { path: '/delegates', exact: true, auth: true, name: 'DelegatePage', component: <DelegatePage/> },
    { path: '/delegates/:userId', exact: true, auth: true, name: 'DelegateStoriesPage', component: <DelegateStoriesPage/> },
    { path: '/delegates/:userId/:storyId/write/:storyPartId', exact: true, auth: true, name: 'DelegateWritePartPage', component: <WritePartPage/> },
    { path: '/delegates/:userId/:storyId/notes/:storyNoteId', exact: true, auth: true, name: 'DelegateStoryNotePage', component: <StoryNotePage/> },
    // settings
    { path: '/settings', exact: true, auth: true, name: 'SettingsPage', component: <SettingsPage/> },

    /**
     * admin routes
    */
    { path: '/admin/login', exact: true, auth: false, name: 'AdminLoginPage', component: <AdminLoginPage/>, isAdmin: true  },
    { path: '/admin/template-lists', exact: true, auth: true, name: 'EmailTemplateList', component: <EmailTemplateList/>, isAdmin: true },
    { path: '/admin/waiting-users', exact: true, auth: true, name: 'WaitingUsersPage', component: <WaitingUsersPage/>, isAdmin: true },
    { path: '/admin/waiting-users/create', exact: true, auth: true, name: 'WaitingUsersCreatePage', component: <WaitingUsersCreatePage/>, isAdmin: true },

];

export default routes;