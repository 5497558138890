import {
  Box,
  InputLabel,
  Typography,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import config from "../../../config/config";

export default function PrimarySelect(props) {
  const {
    label,
    placeholder,
    name,
    handleChange,
    value,
    required = false,
    options,
    sx,
    error = false,
  } = props;

  return (
    <Box>
      <Stack direction={"column"}>
        {label && (
          <InputLabel
            sx={{
              color: config.colors.primaryTextColor,
              fontFamily: "Libre Franklin",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: 700,
              marginBottom: "10px",
            }}
          >
            {label}
            {required && <span style={{ color: "red" }}>*</span>}
          </InputLabel>
        )}
        <Select
          value={value}
          label={""}
          name={name}
          onChange={handleChange}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return <em>{placeholder}</em>;
            }
            const selectedOption = options.find(
              (option) => option.value === selected
            );
            return selectedOption ? selectedOption.label : selected;
          }}
          sx={{
            border: "none",
            display: "flex",
            alignItems: "center",
            padding: "6px 25px",
            boxShadow: "inset 0px 2px 6px #d2d2d2",
            transition:
              "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
            fontFamily: "Libre Franklin",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "28px",
            outline: "none",
            borderRadius: "0",
            borderColor: "none",
            background: "#fff!important",
            ".MuiOutlinedInput-notchedOutline": { border: 0 },
            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
            ...sx,
          }}
        >
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          {options?.map((item, index) => {
            return (
              <MenuItem key={index} value={item?.value}>
                {item?.label}
              </MenuItem>
            );
          })}
        </Select>
        {error && !value && (
          <Typography variant="caption" color={"red"} sx={{ mt: 0.5 }}>
            Required the field
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
