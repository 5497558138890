import React, { useEffect, useState } from "react";
import "../styles/home.scss";
import {
  HomeFooter,
  BetaHeader,
  WaitingListForm,
  HowStoryWorks,
} from "../components";
//
import leftImg from "../../assets/images/waiting-list/left-images.png";
import { Box, Container, Stack, Typography } from "@mui/material";
import config from "../../config/config";

export default function WaitingListPage() {

  const [imageWidth, setImageWidth] = useState(window.innerWidth > 2000 ? "650px" : "40%");

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    if (width > 2000) {
      setImageWidth("680px");
    } else {
      setImageWidth("40%");
    }
  };

  return (
    <Box sx={{ bgcolor: config.colors.bgWhiteColor }}>
      {console.log('hello from waitinglistpage Pv1.1.24')}
      <BetaHeader />
      <Box
        sx={{
          position: {
            md: "absolute",
            sm: "inherit",
          },
          display: "flex",
          justifyContent: {
            md: "end",
            sm: "center",
          },
          top: 0,
          right: 0,
          width: {
            md: "100%",
            sm: "100%",
            xs: "100%",
          },
        }}
      >
        <Box
          sx={{
            width: {
              xl: imageWidth,
              lg: "40%",
              md: "40%",
              sm: "80%",
              xs: "100%",
            },
          }}
        >
          <img src={leftImg} alt="" width={"100%"} />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f7f7f7",
          paddingY: {
            md: 9,
            sm: 5,
          },
          color: config.colors.primaryTextColor,
        }}
      >
        <Container maxWidth="lg">
          <Typography
            sx={{
              color: config.colors.primaryTextColor,
              fontSize: {
                md: "53px",
                xs: "33px",
              },
              fontFamily: "Libre Franklin",
              fontWeight: 800,
            }}
          >
            Welcome To Storymoir!
          </Typography>
          <Typography
            sx={{
              fontSize: {
                md: "30px",
                xs: "22px",
              },
              fontFamily: "Lora",
              fontWeight: 500,
            }}
          >
            Connecting Us Through Stories
          </Typography>
          <Typography
            sx={{
              fontSize: {
                md: "25px",
                xs: "18px",
              },
              fontFamily: "Libre Franklin",
              fontWeight: 400,
            }}
          >
            Storymoir is a new platform to write, read, and share your
            non-fiction life stories.
          </Typography>
          <Stack
            direction={{
              md: "row",
              xs: "column",
            }}
            spacing={2}
            sx={{
              paddingY: 2,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: {
                    md: "25px",
                    xs: "18px",
                  },
                  fontFamily: "Libre Franklin",
                  fontWeight: 400,
                }}
              >
                • Autobiographies
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    md: "25px",
                    xs: "18px",
                  },
                  fontFamily: "Libre Franklin",
                  fontWeight: 400,
                }}
              >
                • Memoirs
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    md: "25px",
                    xs: "18px",
                  },
                  fontFamily: "Libre Franklin",
                  fontWeight: 400,
                }}
              >
                • Life Accounts
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: {
                    md: "25px",
                    xs: "18px",
                  },
                  fontFamily: "Libre Franklin",
                  fontWeight: 400,
                }}
              >
                • Family Stories
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    md: "25px",
                    xs: "18px",
                  },
                  fontFamily: "Libre Franklin",
                  fontWeight: 400,
                }}
              >
                • Short Stories
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    md: "25px",
                    xs: "18px",
                  },
                  fontFamily: "Libre Franklin",
                  fontWeight: 400,
                }}
              >
                • Personal Anecdotes
              </Typography>
            </Box>
          </Stack>
          <Typography
            sx={{
              fontSize: {
                md: "25px",
                xs: "18px",
              },
              fontFamily: "Libre Franklin",
              fontWeight: 400,
            }}
          >
            We’ll be opening our platform soon and we’d like to invite you to be
            among our first users.
          </Typography>
        </Container>
      </Box>
      <WaitingListForm />
      <HowStoryWorks />
      <HomeFooter />
    </Box>
  );
}
