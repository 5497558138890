import { Stack, Typography } from "@mui/material";
import { Favorite } from "@mui/icons-material";

export default function RecommendButton({
  isRecommended,
  recommend,
  totalRecommendations,
}) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{
        cursor: "pointer",
        padding: "8px 16px",
        borderRadius: "20px",
        backgroundColor: isRecommended
          ? "rgba(219, 128, 94, 0.1)"
          : "transparent",
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: isRecommended
            ? "rgba(219, 128, 94, 0.2)"
            : "rgba(0, 0, 0, 0.04)",
        },
      }}
      onClick={recommend}
      title={isRecommended ? "Click to unrecommend" : "Click to recommend"}
    >
      <Typography variant="body2" fontWeight="bold" color="text.secondary">
        {totalRecommendations}
      </Typography>
      <Favorite
        sx={{
          fontSize: 18,
          color: isRecommended ? "var(--bs-orange)" : "action.active",
          transition: "color 0.3s ease",
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
      />
      <Typography variant="body2" fontWeight="medium" color="text.primary">
        {isRecommended ? "Recommended" : "Recommend"}
      </Typography>
    </Stack>
  );
}
