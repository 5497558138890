import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  StoryContext,
  AxiosContext,
  GuestContext,
  AuthContext,
  CommentContext,
} from "../../../context";
import {
  MainHeader,
  Footer,
  StoryBySingleAuthor,
  StoryByCoAuthor,
  ReadingProgressBar,
  DefaultButton,
  PrimaryButton,
  StoryPartCommentInput,
  StoryPartCommentDetail,
} from "../../components";
import { Box, Container, Stack, Typography } from "@mui/material";
import config from "../../../config/config";
import { toast } from "react-toastify";

export default function StoryPage() {
  const navigate = useNavigate();
  const { storyId, storyPartId } = useParams();
  // const { hash, pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const preview = searchParams.get("preview");
  const { setLoading } = useContext(AxiosContext);
  const { token, user } = useContext(AuthContext);
  const { getPrevRoute, isScrolling } = useContext(GuestContext);
  const { getStory, currentStory, updateStoryPart } = useContext(StoryContext);
  const {
    storyPartComments,
    storyPartComment,
    storyPartReply,
    getStoryPartComments,
  } = useContext(CommentContext);

  const [chapterIndex, setChapterIndex] = useState(0);
  const [chapter, setChapter] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (storyId) {
      getStory(storyId).then((res) => {
        if (res.status) {
          setChapter(res.data.story?.storyParts[0]);
          findChapterByPartId(res.data.story, storyPartId || 1);
          getStoryPartComments(storyPartId || 1);
        } else {
          if (!token) {
            let _searchParams = window.location.search
              ? `${window.location.search}&redirect=${window.location.pathname}`
              : `?redirect=${window.location.pathname}`;
            navigate(`/auth/login${_searchParams}`);
          }
        }
        setLoading(false);
        window.scrollTo(0, 0);
      });
    }
  }, [storyId, storyPartId]);

  const findChapterByPartId = (story, _storyPartId) => {
    if (story) {
      const chapters = story.storyParts;
      if (_storyPartId) {
        for (let i = 0; i < chapters.length; i++) {
          if (chapters[i].id.toString() === _storyPartId) {
            setChapterIndex(i);
            setChapter(chapters[i]);
            break;
          }
        }
      } else {
        const _chapter = chapters[0];
        setChapter(_chapter);
        setChapterIndex(0);
      }
    }
  };

  const handlePagination = (pg) => {
    if (
      (pg < 0 && chapterIndex <= 0) ||
      (pg > 0 && chapterIndex + 1 >= currentStory?.storyParts.length)
    ) {
      return;
    }
    let _chapterIndex = chapterIndex + pg;
    setChapterIndex(_chapterIndex);
    let _storyPartId = currentStory.storyParts[_chapterIndex].id;
    navigate(`/story/${storyId}/${_storyPartId}`);
  };

  const handleClickBack = () => {
    let prevRoute = getPrevRoute();
    navigate(
      prevRoute
        ? prevRoute.pathname
        : `/mystories/${storyId}/write/${storyPartId}`
    );
  };

  const handleClickPublish = () => {
    setLoading(true);
    updateStoryPart({ id: storyPartId, status: "PUBLISH" }).then((response) => {
      if (response.status) {
        navigate(`/story/${storyId}/${storyPartId}`);
      }
      setLoading(false);
    });
  };

  const tryComment = (commentText) => {
    if (commentText) {
      const data = {
        userId: user?.id,
        storyId: storyId,
        storyPartId: storyPartId || 1,
        comment: commentText,
        sentence: "",
      };
      storyPartComment(data).then(() => {
        toast("You have commented on this part!");
        getStoryPartComments(storyPartId || 1);
      });
    } else {
      toast("Please input the text");
    }
  };

  const tryReply = (replyText, commentId) => {
    if (!replyText || !commentId) {
      toast("Please input the text");
    } else {
      const data = {
        userId: user?.id,
        commentId: commentId,
        reply: replyText,
      };
      storyPartReply(data).then(() => {
        toast("You have commented on this comment!");
        getStoryPartComments(storyPartId || 1);
      });
    }
  };

  return (
    <Box sx={{ pt: "172px", bgcolor: config.colors.bgWhiteColor }}>
      {!preview ? (
        <MainHeader showReadingProgress={true} />
      ) : (
        <Box sx={{}}>
          <MainHeader showReadingProgress={false} />
          <Box
            sx={{
              position: isScrolling ? "fixed" : "absolute",
              top: isScrolling ? "93px" : "123px",
              width: "100%",
              // height: isScrolling ? "100px" : "166px",
              // display: "flex",
              // alignItems: "center",
              bgcolor: config.colors.bgWhiteColor,
              boxShadow: isScrolling
                ? "0px 2px 5px 0 rgba(71,52,130,.1)"
                : "none",
              zIndex: 1,
            }}
          >
            <Container
              maxWidth={"lg"}
              sx={{
                paddingY: "10px",
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <DefaultButton label="Back" handleClick={handleClickBack} />
                <PrimaryButton
                  label="Publish"
                  handleClick={handleClickPublish}
                />
              </Stack>
            </Container>
            <ReadingProgressBar />
          </Box>
        </Box>
      )}
      {currentStory ? (
        <>
          {currentStory?.delegates && currentStory?.delegates.length > 0 ? (
            <StoryByCoAuthor
              story={currentStory}
              chapter={chapter}
              chapterIndex={chapterIndex}
            />
          ) : (
            <StoryBySingleAuthor
              story={currentStory}
              chapter={chapter}
              chapterIndex={chapterIndex}
              handlePagination={handlePagination}
            />
          )}
          <Container maxWidth="lg" sx={{ marginTop: 5, marginBottom: 2 }}>
            {token && (
              <StoryPartCommentInput
                comment={tryComment}
                user={user}
                value={""}
              />
            )}
            {storyPartComments?.map((storyPartComment, index) => (
              <StoryPartCommentDetail
                key={"story-part-comment-index-" + index}
                comment={storyPartComment}
                reply={tryReply}
                user={user}
              />
            ))}
          </Container>
        </>
      ) : (
        <Typography variant="h6" textAlign={"center"} sx={{ mb: 6 }}>
          You don't have permission.
        </Typography>
      )}
      <Footer />
    </Box>
  );
}
