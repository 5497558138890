import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

const ShareModal = ({ showModal, setShowModal, storyUrl }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(storyUrl);
    setCopied(true);
    toast.success("Link copied to clipboard!");
    setTimeout(() => setCopied(false), 3000);
    setShowModal(false);
  };

  const handleEmailShare = () => {
    const subject = encodeURIComponent("Check out this story!");
    const body = encodeURIComponent(
      `I thought you might enjoy this story: ${storyUrl}`
    );
    window.open(`mailto:?subject=${subject}&body=${body}`, "_blank");
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      centered
      className="share-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Share this story</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="share-instruction">Choose how you'd like to share:</p>
        <div
          className="share-buttons"
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="primary"
            className="btn btn-primary share-button"
            onClick={handleCopyLink}
          >
            {copied ? "Copied!" : "Copy Link"}
          </Button>
          <Button
            variant="outline-primary"
            className="btn btn-secondary share-button"
            onClick={handleEmailShare}
          >
            Share via Email
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
