import React, { useEffect } from 'react';
import { MainHeader, Footer } from '../components';
import '../styles/policy.scss';

export default function LegalAgreementPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="page policy bg-white">
            <MainHeader />
            <div className="container">
                <div className="sub-container">
                    <div className="policy-content">
                        <h1 className="headline">Legal Agreement</h1>
                        <div className="policy-date">Last Revised: Feb 12, 2023</div>
                        <a href="#" className="policy-link mb-4">(click here for previous version)</a>
                        <p>
                            Lorem ipsum dolor sit amet, ne ius meis liber, eleifend platonem eos an,
                            eu numquam euismod phaedrum eos. Mea dolorem philosophia ad. Eripuit conceptam
                            ei sea, vim audiam assentior ea. Vim brute intellegam no.
                        </p>
                        <p>
                            Vel at utroque salutatus, no qui libris integre, solet sensibus intellegebat ut mea.
                            Omnes scribentur sit et, mel diam dicam eu. Soluta splendide mea ad, eros ferri est ex.
                            Ex hinc nullam ceteros mei, ullum elitr eruditi ex vel. Pro an doctus quaeque euismod.
                            Usu et minim accumsan insolens.
                        </p>
                        <h5 className="mt-3">Legal</h5>
                        <p>
                            An iuvaret nominavi his, ei tractatos eloquentiam usu. Ut lucilius probatus deserunt pri,
                            ea duo idque laoreet. His ne omnis nostro atomorum, omnesque inciderint cotidieque per ne.
                            Sed tantas platonem ad. Eum habemus propriae no. Quo nullam utroque accusamus id.
                            Alia natum eripuit ad mea, option blandit temporibus an cum. Vis eu enim soluta nostro,
                            id has modo phaedrum dissentias. Legendos ullamcorper nam an, at est persecuti philosophia.
                            Invidunt ocurreret reformidans ex has. Eos ne adhuc animal eloquentiam.
                        </p>
                        <p>
                            Vel modo antiopam eu, eruditi epicurei reformidans eam no, et vis oratio convenire.
                            Sanctus denique tincidunt vis ex, sed eu nonumes evertitur. Ocurreret democritum efficiendi
                            at vis, cum ad habemus facilisi instructior, mutat appetere consulatu eos ne. Sit ut quod
                            impedit maiorum, ut qui mandamus definiebas, adhuc verear invidunt te vel. Vel at utroque
                            salutatus, no qui libris integre, solet sensibus intellegebat ut mea. Omnes scribentur sit
                            et, mel diam dicam eu. Soluta splendide mea ad, eros ferri est ex. Ex hinc nullam ceteros
                            mei, ullum elitr eruditi ex vel. Pro an doctus quaeque euismod. Usu et minim accumsan
                            insolens.
                        </p>
                        <h5 className="mt-3">Legal</h5>
                        <p>
                            Lorem ipsum dolor sit amet, ne ius meis liber, eleifend platonem eos an, eu numquam euismod
                            phaedrum eos. Mea dolorem philosophia ad. Eripuit conceptam ei sea, vim audiam assentior ea.
                            Vim brute intellegam no.
                        </p>
                        <p>
                            Vel at utroque salutatus, no qui libris integre, solet sensibus intellegebat ut mea.
                            Omnes scribentur sit et, mel diam dicam eu. Soluta splendide mea ad, eros ferri est ex.
                            Ex hinc nullam ceteros mei, ullum elitr eruditi ex vel. Pro an doctus quaeque euismod.
                            Usu et minim accumsan insolens.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}