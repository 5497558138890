import { Link, useLocation, useParams } from "react-router-dom";
import imgAuthor from "../../../assets/images/avatar.svg";
import iconTwitter from "../../../assets/images/icons/ic_twitter.svg";
import iconFacebook from "../../../assets/images/icons/ic_facebook.svg";
import iconLinkedin from "../../../assets/images/icons/ic_linkedin.svg";
import iconLink from "../../../assets/images/icons/ic_link.svg";
import iconShare from "../../../assets/images/icons/ic_share.svg";
import { useContext, useState } from "react";
import { AuthPromptModal } from "../../components";
import { AuthContext, UserContext, StoryContext } from "../../../context";
import { toast } from "react-toastify";
import { Avatar, Box, Container, Stack, Typography } from "@mui/material";
import config from "../../../config/config";

export default function ProfileBanner({ author }) {
  const location = useLocation();
  const { storyId } = useParams();
  const [showLoginPromptModal, setShowLoginPromptModal] = useState(false);
  const { token, user } = useContext(AuthContext);
  const { follow } = useContext(UserContext);
  const { getStory } = useContext(StoryContext);

  const handleFollowClick = (isfollowed) => {
    if (token && user) {
      follow(user.id, { followerId: author?.id, isFollow: !isfollowed }).then(
        (res) => {
          toast(res.data);
          if (res.status) {
            getStory(storyId);
          }
        }
      );
    } else {
      setShowLoginPromptModal(true);
    }
  };

  const goToBlankPage = (url) => {
    if (!url.includes("https://")) {
      url = `https://${url}`;
    }
    window.open(url, "_blank");
  };

  return (
    <Box sx={{ bgcolor: config.colors.primaryBgColor }}>
      <Container
        maxWidth={"lg"}
        sx={{
          position: "relative",
          width: "100%",
        }}
      >
        {user && author?.id === user?.id && (
          <Link
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "112px",
              height: "40px",
              backgroundColor: config.colors.bgWhiteColor,
              border: "1px solid #D9D9D9",
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
              fontFamily: "Libre Franklin",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "30px",
              color: config.colors.primaryTextColor,
              textDecoration: "none",
            }}
            to={"/profile/edit"}
          >
            Edit Profile
          </Link>
        )}
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          spacing={3}
          sx={{
            paddingY: "30px",
          }}
        >
          <Link
            to={`/user/${author?.id}`}
            style={{
              textDecoration: "none",
            }}
          >
            <Avatar
              src={author?.imageUrl ? author?.imageUrl : imgAuthor}
              sx={{
                width: "179px",
                height: "179px",
              }}
            />
          </Link>
          <Box>
            <Stack
              sx={{
                mt: "50px",
              }}
            >
              <Stack direction={"column"} spacing={0}>
                <Box>
                  <Link
                    style={{
                      textDecoration: "none",
                    }}
                    to={`/user/${author?.id}`}
                  >
                    <Typography
                      sx={{
                        color: config.colors.primaryTextColor,
                        fontFamily: "Libre Franklin",
                        fontSize: "30px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "28px",
                      }}
                    >
                      {author?.fullName ? author?.fullName : author?.username}
                    </Typography>
                  </Link>
                  <Typography
                    sx={{
                      color: config.colors.primaryTextColor,
                      fontFamily: "Libre Franklin",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "28px",
                    }}
                  >
                    Asheville, NC USA
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color: config.colors.primaryTextColor,
                    fontFamily: "Libre Franklin",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "28px",
                  }}
                >
                  {author?.followerUsers
                    ? author?.followerUsers.filter(
                        (follower) => follower.active
                      ).length
                    : 0}{" "}
                  Followers •{" "}
                  {author?.followingUsers
                    ? author?.followingUsers.filter(
                        (following) => following.active
                      ).length
                    : 0}{" "}
                  Following
                </Typography>
                <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
                  <img
                    src={iconTwitter}
                    alt="twitter"
                    style={{
                      cursor: "pointer",
                      width: "16px",
                      height: "16px",
                    }}
                    onClick={() => {
                      goToBlankPage(`${author?.twitter}`);
                    }}
                  />
                  <img
                    src={iconFacebook}
                    alt="facebook"
                    style={{
                      cursor: "pointer",
                      width: "16px",
                      height: "16px",
                    }}
                    onClick={() => {
                      goToBlankPage(`${author?.facebook}`);
                    }}
                  />
                  <img
                    src={iconLinkedin}
                    alt="linkedin"
                    style={{
                      cursor: "pointer",
                      width: "16px",
                      height: "16px",
                    }}
                    onClick={() => {
                      goToBlankPage(`${author?.linkedin}`);
                    }}
                  />
                  <img
                    src={iconLink}
                    alt="link"
                    style={{
                      cursor: "pointer",
                      width: "16px",
                      height: "16px",
                    }}
                    onClick={() => {
                      goToBlankPage(`${author?.website}`);
                    }}
                  />
                  {/* <img src={iconShare} className="" alt="Share" style={{
                    cursor: "pointer",
                    width: "30px",
                    height: "30px"
                }} /> */}
                </Stack>
              </Stack>
              <Typography
                sx={{
                  mt: "30px",
                  fontFamily: "Libre Franklin",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "22px",
                  lineHeight: "28px",
                  color: config.colors.primaryTextColor,
                }}
              >
                {author?.about}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
