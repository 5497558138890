import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckIcon from "@mui/icons-material/Check";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MenuIcon from "@mui/icons-material/Menu";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment";

import { AuthContext, GuestContext, StoryContext } from "../../../context";
import {
  AppBar,
  Avatar,
  Box,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import config from "../../../config/config";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import PrimaryButton from "../buttons/primary_button";
import DefaultButton from "../buttons/default_button";
import TextButton from "../buttons/text_button";
import GreyButton from "../buttons/grey_button";

export default function WritingHeader(props) {
  const {
    storyPartId,
    currentStory,
    formData,
    handleClickPublish,
    handleClickPreview,
    handleClickNewChapter,
    handleClickSaveDraft,
    handleUpdateTitle,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useContext(AuthContext);
  const { setPrevRoute, isScrolling, setIsScrolling } =
    useContext(GuestContext);
  const { updateStoryPartsOrder } = useContext(StoryContext);

  const [partsAnchorEl, setPartsAnchorEl] = useState(null);
  const [partsList, setPartsList] = useState(currentStory?.storyParts);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editValue, setEditValue] = useState(formData?.title);

  useEffect(() => {
    setPartsList(currentStory?.storyParts);
  }, [currentStory]);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollEvent);
    return () => window.removeEventListener("scroll", handleScrollEvent);
  }, []);

  const handleScrollEvent = (e) => {
    if (e.target.documentElement.scrollTop > 0) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  const partsHandleClick = (e) => {
    setPartsAnchorEl(partsAnchorEl ? null : e.currentTarget);
  };

  const menuHandleClick = (e) => {
    setMenuAnchorEl(menuAnchorEl ? null : e.currentTarget);
  };

  const handleClose = () => {
    setPartsAnchorEl(null);
    setMenuAnchorEl(null);
  };

  const canBePartsOpen = Boolean(partsAnchorEl);
  const id = canBePartsOpen ? "parts-transition-popper" : undefined;
  const canBeMenuOpen = Boolean(menuAnchorEl);
  const menuId = canBeMenuOpen ? "basic-menu-id" : undefined;

  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...partsList];
    const selectedItem = updatedList[droppedItem.source.index];
    // Remove dragged item
    updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, selectedItem);
    // Update State
    setPartsList(updatedList);
    updateStoryPartsOrder({ storyParts: updatedList });
  };

  const getListStyle = (isDraggingOver) => ({});

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const handleClickMenu = (route) => {
    setPrevRoute(location);
    navigate(route);
  };

  const handleLogout = () => {
    logout().then((res) => {
      if (res) {
      }
    });
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: config.colors.bgWhiteColor,
        boxShadow: isScrolling ? "0px 2px 5px 0 rgba(71,52,130,.1)" : "none",
        // height: isScrolling ? "93px" : "123px",
      }}
    >
      <Container maxWidth={"lg"}>
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={2}
          p={1}
        >
          <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
            <IconButton
              size="small"
              onClick={() => {
                // navigate(-1);
                navigate(`/library`);
              }}
            >
              <ArrowBackIosIcon
                sx={{
                  color: config.colors.defaultColor,
                }}
              />
            </IconButton>
            <Box>
              <Stack
                direction="column"
                sx={{
                  cursor: "pointer",
                }}
                aria-describedby={id}
              >
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent={"start"}
                  spacing={0}
                  onClick={partsHandleClick}
                >
                  <Typography
                    sx={{
                      color: config.colors.greyTextColor,
                      fontSize: 16,
                      fontFamily: "Libre Franklin",
                      m: 0,
                      p: 0,
                    }}
                  >
                    {currentStory?.title}
                  </Typography>
                  <KeyboardArrowDownIcon
                    sx={{
                      fontSize: 26,
                      color: config.colors.defaultColor,
                    }}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={2}
                  sx={{ mb: 1 }}
                >
                  {!isEdit && (
                    <>
                      <Typography
                        sx={{
                          color: config.colors.primaryTextColor,
                          fontSize: 20,
                          fontFamily: "Libre Franklin",
                          m: 0,
                          p: 0,
                        }}
                      >
                        {formData?.title}
                      </Typography>
                      <CreateOutlinedIcon
                        sx={{
                          fontSize: 20,
                          color: config.colors.defaultColor,
                        }}
                        onClick={() => {
                          setIsEdit(true);
                        }}
                      />
                    </>
                  )}
                  {isEdit && (
                    <>
                      <TextField
                        margin="none"
                        size="small"
                        value={editValue}
                        onChange={(e) => {
                          setEditValue(e.target.value);
                        }}
                        sx={{
                          padding: 0,
                        }}
                      />
                      <PrimaryButton
                        label="Update"
                        height={25}
                        width={40}
                        sx={{
                          fontSize: 13,
                        }}
                        handleClick={async () => {
                          await handleUpdateTitle(editValue);
                          setIsEdit(false);
                        }}
                      />
                      <CloseOutlinedIcon
                        sx={{
                          fontSize: 20,
                          color: config.colors.defaultColor,
                        }}
                        onClick={() => {
                          setEditValue(formData?.title);
                          setIsEdit(false);
                        }}
                      />
                    </>
                  )}
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"start"}
                  spacing={1}
                >
                  <Typography
                    sx={{
                      color:
                        formData.status === "DRAFT"
                          ? config.colors.defaultColor
                          : config.colors.greyTextColor,
                      fontSize: 14,
                      fontFamily: "Libre Franklin",
                      m: 0,
                      p: 0,
                    }}
                  >
                    Draft
                  </Typography>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      width: "2px",
                      bgcolor: config.colors.primaryTextColor,
                    }}
                  />
                  <Typography
                    sx={{
                      color:
                        formData.status != "DRAFT"
                          ? config.colors.defaultColor
                          : config.colors.greyTextColor,
                      fontSize: 14,
                      fontFamily: "Libre Franklin",
                      m: 0,
                      p: 0,
                    }}
                  >
                    Saved
                  </Typography>
                </Stack>
              </Stack>
              <Popover
                id={id}
                open={canBePartsOpen}
                anchorEl={partsAnchorEl}
                sx={{
                  zIndex: 1111,
                  mt: 8,
                  bgcolor: "transparent",
                  "& .MuiPaper-root": {
                    bgcolor: "transparent !important",
                    boxShadow: "none",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                onClose={handleClose}
              >
                <Box
                  sx={{
                    border: `3px solid ${config.colors.greyTextColor}`,
                    borderRadius: "4px",
                    bgcolor: config.colors.bgWhiteColor,
                    position: "relative",
                    mt: 1,
                    "&:after": {
                      bottom: "100%",
                      left: "7%",
                      border: "solid transparent",
                      content: '" "',
                      height: 0,
                      width: 0,
                      position: "absolute",
                      pointerEvents: "none",
                      borderBottomColor: config.colors.greyTextColor,
                      borderWidth: "10px",
                      marginLeft: "-10px",
                    },
                  }}
                >
                  <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId="list-container">
                      {(provided, snapshot) => {
                        return (
                          <Box
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            sx={{
                              ...getListStyle(snapshot.isDraggingOver),
                            }}
                          >
                            {partsList?.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item?.id?.toString()}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        spacing={2}
                                        ref={provided.innerRef}
                                        {...provided.dragHandleProps}
                                        {...provided.draggableProps}
                                        onClick={() => {
                                          handleClose();
                                          navigate(
                                            `/mystories/${item.storyId}/write/${item.id}`
                                          );
                                        }}
                                        sx={{
                                          p: "10px 20px",
                                          borderBottom: `1px dotted ${config.colors.greyTextColor}`,
                                          ...getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                          ),
                                        }}
                                      >
                                        <Box>
                                          <Typography>{item?.title}</Typography>
                                          <Typography>
                                            {item.status.toLowerCase()} -{" "}
                                            {moment(item.updatedAt).format(
                                              "MMM DD, YYYY"
                                            )}
                                          </Typography>
                                        </Box>
                                        {item?.id == storyPartId && (
                                          <CheckIcon
                                            sx={{
                                              fontSize: 26,
                                              color: config.colors.defaultColor,
                                            }}
                                          />
                                        )}
                                      </Stack>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </Box>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                  <TextButton
                    label={"New Part"}
                    handleClick={() => {
                      handleClose();
                      handleClickNewChapter();
                    }}
                    startIcon={<AddCircleOutlineIcon />}
                  />
                </Box>
              </Popover>
            </Box>
          </Stack>
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            alignItems={"center"}
            spacing={4}
          >
            <Stack direction={"row"} spacing={2}>
              <GreyButton
                label={"Save Draft"}
                handleClick={handleClickSaveDraft}
                width={150}
              />
              <DefaultButton
                label={"Preview"}
                handleClick={handleClickPreview}
                width={120}
              />
              <PrimaryButton
                label={"Publish"}
                handleClick={handleClickPublish}
                width={120}
              />
            </Stack>
            <Stack
              direction={"row"}
              spacing={3}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              {!user ? (
                <PrimaryButton
                  label="Sign Up"
                  handleClick={() => navigate(`/auth/register`)}
                />
              ) : (
                <Avatar
                  src={user?.imageUrl}
                  sx={{
                    width: 56,
                    height: 56,
                  }}
                />
              )}
              {user && (
                <Box>
                  <MenuIcon
                    sx={{
                      cursor: "pointer",
                      fontSize: 50,
                      color: config.colors.greyTextColor,
                    }}
                    id={menuId}
                    aria-controls={canBeMenuOpen ? menuId : undefined}
                    aria-haspopup="true"
                    aria-expanded={canBeMenuOpen ? "true" : undefined}
                    onClick={menuHandleClick}
                  />
                  <Menu
                    id={menuId}
                    anchorEl={menuAnchorEl}
                    open={canBeMenuOpen}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    sx={{
                      "& .MuiMenu-paper": {
                        backgroundColor: config.colors.primaryTextColor,
                        color: config.colors.bgWhiteColor,
                        borderTopLeftRadius: 40,
                        pt: 4,
                        paddingX: 2,
                      },
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate(`/user/${user.id}`);
                      }}
                      sx={{
                        borderBottom: `1px dotted ${config.colors.bgWhiteColor}`,
                        paddingX: 4,
                        color: config.colors.bgWhiteColor,
                      }}
                    >
                      Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate(`/mystories/new`);
                      }}
                      sx={{
                        borderBottom: `1px dotted ${config.colors.bgWhiteColor}`,
                        paddingX: 4,
                        color: config.colors.bgWhiteColor,
                      }}
                    >
                      Write a Story
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate("/library");
                      }}
                      sx={{
                        borderBottom: `1px dotted ${config.colors.bgWhiteColor}`,
                        paddingX: 4,
                        color: config.colors.bgWhiteColor,
                      }}
                    >
                      Library
                    </MenuItem>
                    {/* <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate(`/delegates`);
                      }}
                      sx={{
                        borderBottom: `1px dotted ${config.colors.bgWhiteColor}`,
                        paddingX: 4,
                      }}
                    >
                      Delegate Acc
                    </MenuItem> */}
                    {/* <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate(`#/languages`);
                      }}
                      sx={{
                        borderBottom: `1px dotted ${config.colors.bgWhiteColor}`,
                        paddingX: 4,
                        color: config.colors.bgWhiteColor,
                      }}
                    >
                      Languages
                    </MenuItem> */}
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        navigate(`settings`);
                      }}
                      sx={{
                        borderBottom: `1px dotted ${config.colors.bgWhiteColor}`,
                        paddingX: 4,
                        color: config.colors.bgWhiteColor,
                      }}
                    >
                      Settings
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        handleLogout();
                      }}
                      sx={{
                        paddingX: 4,
                        mb: 5,
                        color: config.colors.bgWhiteColor,
                      }}
                    >
                      Log off
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </AppBar>
  );
}
